


















































import { ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm'
import { Component, Vue } from 'vue-property-decorator'
import InputNumber from '@/components/Controls/InputNumber.vue'
import InputTextarea from '@/components/Controls/InputTextarea.vue'
import Calendar from '@/components/Controls/Calendar.vue'
import { componentsImports } from '../FormFields/index'

@Component({
  components: {
    ...componentsImports(),
    ValidationProvider,
    InputNumber,
    InputTextarea,
    Calendar
  }
})
export default class ResidentialApartment extends Vue {
  formData () {
    return {
      GeneralCondition: this.generalCondition,
      FloorConstruction: this.floorConstruction,
      WallConstruction: this.wallConstruction,
      RoofConstruction: this.roofConstruction,
      ApproximateBuildYear: this.approximateBuildYear,
      EstimatedValue: this.estimatedValue,
      CurrentRental: this.currentRental,
      NumberOfBedrooms: this.bedrooms,
      NumberOfBathrooms: this.bathrooms,
      CarParks: this.carParks,
      FloorArea: this.floorArea,
      FloorLevel: this.floorLevel,
      Other: this.other,
      PropertyFeatures: this.propertyFeatures
    }
  }

  loadData (data: any) {
    this.generalCondition = data.GeneralCondition
    this.floorConstruction = data.FloorConstruction
    this.wallConstruction = data.WallConstruction
    this.roofConstruction = data.RoofConstruction
    this.approximateBuildYear = data.ApproximateBuildYear
    this.estimatedValue = data.EstimatedValue
    this.currentRental = data.CurrentRental
    this.bedrooms = data.NumberOfBedrooms
    this.bathrooms = data.NumberOfBathrooms
    this.carParks = data.CarParks
    this.floorArea = data.FloorArea
    this.floorLevel = data.FloorLevel
    this.other = data.Other
    this.propertyFeatures = data.PropertyFeatures
  }

  propertyType () {
    return 'ResidentialApartment'
  }

  generalCondition: any = null
  approximateBuildYear: any = null
  floorArea: any = null
  carParks: any = null
  floorConstruction: any = null
  wallConstruction: any = null
  roofConstruction: any = null
  estimatedValue: any = null
  currentRental: any = null
  bedrooms: any = null
  bathrooms: any = null
  floorLevel: any = null
  other: any = null
  propertyFeatures: string[] = []
}
